






































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  toRefs,
  onMounted
} from '~/utils/nuxt3-migration'

import CBadge360 from '~/components/shared/configurable/badge/CBadge360.vue'
import { ciPlay } from '~/icons/source/regular/play'
import { useLogger } from '~/compositions/logger'

export default defineComponent({
  components: { CBadge360 },
  props: {
    image: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    videoShown: {
      type: Boolean,
      required: false,
      default: false
    },
    inSlider: {
      type: Boolean,
      required: false,
      default: false
    },
    inZoomer: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props, { emit }) {
    const imageRef = ref(null)
    const thumbLoaded = ref(false)
    const { inSlider, inZoomer, image } = toRefs(props)
    const logger = useLogger()

    const imageSource = computed(() => {
      if (inSlider.value) {
        if (image.value.type === 'img360' && image.value.bigthumb) {
          return image.value.bigthumb
        }
        return image.value.medium
      }
      if (inZoomer.value) {
        if (image.value.type === 'image' || image.value.type === 'img') {
          return image.value.url
        } else if (image.value.type === 'pdf') {
          return require('~/assets/img/icons/icon_pdf.png')
        }
      }

      if (image.value.thumb) {
        return image.value.thumb
      }

      logger.captureError(new Error(`no thumb source found`))
      return null
    })
    onMounted(() => {
      if (imageRef.value && imageRef.value.complete) {
        thumbLoad()
      } else {
        const image = new Image()

        image.onload = () => {
          thumbLoad()
        }
        image.onerror = () => {
          emit('remove-image-index')
        }
        image.src = imageSource.value
      }
    })
    onBeforeUnmount(() => {
      const elem = imageRef.value[0]
      if (elem) {
        elem.onload = null
      }
    })

    function thumbLoad() {
      emit('image-loaded')
      emit('increase-thumbs-loaded')
      thumbLoaded.value = true
    }
    function thumbClicked() {
      if (thumbLoaded.value) {
        emit('clicked')
      }
    }

    return {
      thumbLoaded,
      imageSource,
      playShortVideo: ciPlay,
      imageRef,
      thumbLoad,
      thumbClicked
    }
  }
})
