











import { ci360Logo } from '~/icons/source/solid/360logo'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    return { ci360Logo }
  }
})
